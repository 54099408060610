// Define every font setting here and include mixin where needed. This way we can combine font families, sizes and weights
.black {
    color: black;
}

.grey {
    color: #E3E5E6;
}

.red {
    color: red;
}

.orange {
    color: orange;
}

#loader-container {
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 100;
    padding: 10px;
    display: none;
    border: 1px solid black;
    background-color: white;
}
#loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}