.fl-left { float: left; }
.fl-right { float: right; }

.ta-l { text-align: left; }
.ta-c { text-align: center; }
.ta-r { text-align: right; }

.display-inline-block { display: inline-block; }

.hidden {
    display: none;
}

.hidden-phone {
    @include bp-phone {
        display: none;
    }
}

.visible-only-phone {
    @include bp-over-phone {
        display: none;
    }
}

.hidden-desktop {
    @include bp-desktop {
        display: none;
    }
}
