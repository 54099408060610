.typeahead {
    position: relative;
    margin-bottom: 20px;
}

.typeahead input {
    width: 100%;
    font-size: 14px;
    color: #2c3e50;
    line-height: 1.42857143;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, .4);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    font-weight: 300;
    padding: 12px 26px;
    border: none;
    border-radius: 22px;
    letter-spacing: 1px;
    box-sizing: border-box;
}

.typeahead input:focus {
    border-color: #4fc08d;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #4fc08d;
}

.typeahead {

    .fa-times {
        cursor: pointer;
    }

    i {
        float: right;
        position: relative;
        top: 30px;
        right: 29px;
        opacity: 0.4;
    }

    ul {
        position: absolute;
        padding: 0;
        margin-top: 8px;
        min-width: 100%;
        background-color: #fff;
        list-style: none;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        z-index: 1000;
    }

    li {
        padding: 10px 16px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
    }

    li:first-child {
        border-radius: 4px 4px 0 0;
    }

    li:last-child {
        border-radius: 0 0 4px 4px;
        border-bottom: 0;
    }

    span {
        display: block;
        color: #2c3e50;
    }

    .active {
        background-color: #3aa373;
    }

    .active span {
        color: white;
    }

    .name {
        font-weight: 700;
        font-size: 18px;
    }

    .screen-name {
        font-style: italic;
    }
}
