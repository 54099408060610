
/******************************
 *
 *  Breakpoints
 *
 ******************************/

// iPhones, Galaxy, HTC, Smartphones
@mixin bp-phone() {
    @media only screen and (min-width: 0px) and (max-width: 667px) {
        @content;
    }
}

// iPhones, Galaxy, HTC, Smartphones
@mixin bp-over-phone() {
    @media only screen and (min-width: 668px) {
        @content;
    }
}

// iPads, Tablets
@mixin bp-tablet() {
    @media only screen and (min-width: 668px) and (max-width: 1024px) {
        @content;
    }
}

// Phones AND tablets
@mixin bp-mobile() {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

// All screens, bigger than tablets
@mixin bp-desktop() {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}

// Only big desktop screens
@mixin bp-desktop-large() {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}

/******************************
*
*  Grid
*
******************************/



/******************************
 *
 *  Typo
 *
 ******************************/

$f-title: Helvetica, Arial, sans-serif;
$f-text: Helvetica, Arial, sans-serif;

$f-size-title-1: 28px;
$f-size-body-1: 14px;

/******************************
 *
 *  Colors
 *
 ******************************/

$c-text: #000;

