* {
    //margin: 0;
    //padding: 0;
}

html, body {
    //height: 100%;
    //max-width: 100%;
}

body {
    font-family: $f-text;
    -webkit-font-smoothing: antialiased;
    //overflow-x: hidden;
}

aside.left-panel {
    overflow: scroll;
}

.btn-purple {
    background: #7b5ee7;
    color: white;
}

.btn-teal {
    background: #4be2c1;
    color: white;
}

.active-red {
    background: #fff2f5 !important;
}

.highlight-row {
    background: #d4f5ff !important;
}

tr:nth-of-type(odd).active-red {
    background: #f7dce2 !important;
}

.stock-red {
    color: white;
    background:#ff0f0f !important;
}

.stock-orange {
    color: white;
    background: #ff7b0f !important;
}

.stock-faded {
    opacity: .75 !important;
}

.stock-overview > tbody > tr > td {
    padding: 8px;
    vertical-align: middle;
}

/*
  Table hover color
*/
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #e0e0e0;
}

.faded-tab {
    opacity: 0.1;
}
