@font-face {
    font-family: 'HelveticaNeueLTStd-Md';
    src: url('../fonts/312C70_0_0.eot');
    src: url('../fonts/312C70_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/312C70_0_0.woff2') format('woff2'), url('../fonts/312C70_0_0.woff') format('woff'), url('../fonts/312C70_0_0.ttf') format('truetype'), url('../fonts/312C70_0_0.svg#wf') format('svg');
}

.mirror {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}